import React from 'react'
import styles from './style.module.scss'
import { SectionTitle } from '@Root/Components/Titles'
import { Field } from '@Root/Components/Field'
import { hearAboutUsOption } from '@Configs/options'

export const AboutUs = ({ onChange, data, isMobile, errors = {} }) => {
	const { hear_about_us, other_hear_about_us } = data

	return (
		<div className={styles.section}>
			<SectionTitle>9. How did you hear about us?</SectionTitle>
			<div>
				<div className={styles.fields}>
					<Field
						label={'How did you hear about the Cambridge Theological Federation? Tick all that apply.'}
						style={{ color: '#9B9B9B', fontWeight: 'bold', width: isMobile ? '100%' : '240px', borderColor: 'transparent' }}
						value={hear_about_us}
						onChange={onChange('hear_about_us')}
						options={hearAboutUsOption}
						fieldClass={'single'}
						overrideStrings={{
							selectSomeItems: 'Select one or more options...',
						}}
						disableSearch
						field={'multiSelect'}
					/>
					{hear_about_us.length !== 0 && (
						<div data-test-id='multi-select-option'>
							<div className={styles.selected_programme_title}>Selected options: </div>
							{hearAboutUsOption?.map((option, i) => {
								return (
									hear_about_us.some((selected) => selected === option.value) && (
										<div key={i} className={styles.selected_programme_item}>
											{option.label}
										</div>
									)
								)
							})}
						</div>
					)}
					{hear_about_us.includes(8) && (
						<Field
							label={'Other:'}
							field={'textField'}
							//Note: 20px - right and left padding of the field
							style={{ color: '#9B9B9B', fontWeight: 'bold', width: isMobile ? 'calc(100% - 20px)' : '268px' }}
							value={other_hear_about_us}
							onChange={onChange('other_hear_about_us')}
							maxLength={100}
							fieldClass={'long'}
						/>
					)}
				</div>
			</div>
		</div>
	)
}
