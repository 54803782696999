import React from 'react'
import styles from './style.module.scss'
import { SectionTitle } from '@Root/Components/Titles'
import { Field } from '@Root/Components/Field'
import { britishVisaOptions, booleanOptions, exameSertificate } from '@Root/configs'

export const InternationalApplicants = ({ onChange, onChangeFormTable, data, isMobile, options, onAddRow, onRemoveRow, errors = {} }) => {
	const { british_visa_status, country_birth, country_of_permanent, is_english_first_language, taken_IELTS_exam = [] } = data
	return (
		<div className={styles.section}>
			<SectionTitle id={'nationality'}>2. Nationality</SectionTitle>
			<div className={styles.fields}>
				<Field
					label={'Country of birth*'}
					options={options.countries}
					field={'select'}
					style={{ color: '#9B9B9B', fontWeight: 'bold', width: isMobile ? '100%' : '260px' }}
					optionsStyle={{ color: '#9B9B9B', fontWeight: 'bold', width: isMobile ? '100%' : '260px' }}
					value={country_birth}
					onChange={onChange('country_birth')}
					isInline
					error={errors.country_birth}
					fieldClass={'long'}
				/>{' '}
				<Field
					label={'Country of permanent residence*'}
					options={options.countries}
					field={'select'}
					style={{ color: '#9B9B9B', fontWeight: 'bold', width: isMobile ? '100%' : '260px' }}
					optionsStyle={{ color: '#9B9B9B', fontWeight: 'bold', width: isMobile ? '100%' : '260px' }}
					value={country_of_permanent}
					onChange={onChange('country_of_permanent')}
					isInline
					error={errors.country_of_permanent}
					fieldClass={'long'}
				/>
				<Field
					label={'UK visa status'}
					options={britishVisaOptions}
					field={'radioInput'}
					style={{ color: '#9B9B9B', fontWeight: 'bold', width: '150px' }}
					value={british_visa_status}
					onChange={onChange('british_visa_status')}
					isInline
					fieldClass={'long'}
				/>
				<Field
					label={'Is English your first language? If so, please move on to section 3'}
					options={booleanOptions}
					field={'radioInput'}
					style={{ color: '#9B9B9B', fontWeight: 'bold', width: '150px' }}
					value={is_english_first_language ? 1 : 2}
					onChange={(value) => onChange('is_english_first_language')(value === 1)}
					isInline
					fieldClass={'long'}
				/>
				{!is_english_first_language && (
					<Field
						label={
							'If English is not your first language, please provide IELTS examination results or equivalent below. You will be asked to provide copies of any certificates during your application process. Please note that TOEFL results are not accepted by Durham University.'
						}
						options={exameSertificate}
						field={'formTable'}
						rows={taken_IELTS_exam}
						onAddRow={onAddRow('taken_IELTS_exam')}
						onRemoveBtn={onRemoveRow('taken_IELTS_exam')}
						style={{ color: '#9B9B9B', fontWeight: 'bold', width: '150px' }}
						onChange={onChangeFormTable('taken_IELTS_exam')}
						fieldClass={'single'}
					/>
				)}
			</div>
			<p>
				NB:{' '}
				<span style={{ color: '#9B9B9B' }}>
					ALL applicants must present a passport at interview as proof of identity. Non UK applicants should provide an officially notarised copy of their
					current passport with their application.
				</span>
			</p>
		</div>
	)
}
