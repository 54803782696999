import React from 'react'
import styles from './style.module.scss'
import { SectionTitle } from '@Root/Components/Titles'
import { Field } from '@Root/Components/Field'
import { booleanOptions, timesOptions } from '@Root/configs'

export const Programme = ({ onChange, data, errors = {}, isMobile, options, onQuestionsChange }) => {
	const { church_of_england, study_program, full_time, launching_educational, institution_id, questions } = data

	return (
		<div className={styles.section}>
			<SectionTitle id={'your_programme'}>3. Your programme</SectionTitle>
			<div className={styles.fields}>
				<div id={errors.study_program ? 'scrollHere' : null}>
					<Field
						label={'Which study programme(s) are you interested in pursuing?'}
						style={{ color: '#9B9B9B', fontWeight: 'bold', width: isMobile ? '100%' : '240px', borderColor: errors.study_program ? 'red' : 'transparent' }}
						value={study_program}
						onChange={onChange('study_program')}
						options={options.programmes}
						fieldClass={'single'}
						overrideStrings={{
							selectSomeItems: 'Select one or more programmes...',
						}}
						disableSearch
						field={'multiSelect'}
					/>
				</div>
				{study_program.length !== 0 && (
					<div data-test-id='multi-select-option'>
						<div className={styles.selected_programme_title}>Selected programmes: </div>
						{options?.programmes?.map((option, i) => {
							return (
								study_program.some((selected) => selected === option.value) && (
									<div key={i} className={styles.selected_programme_item}>
										{option.label}
									</div>
								)
							)
						})}
					</div>
				)}
				<Field
					label={'Institution'}
					field={'select'}
					style={{ color: '#9B9B9B', fontWeight: 'bold', width: '100%' }}
					value={institution_id}
					onChange={onChange('institution_id')}
					isInline
					error={errors.institution_id}
					options={options.institutions}
					maxLength={100}
					optionsStyle={{ width: isMobile ? '96%' : '464px' }}
					isRequired
					fieldClass={'main'}
				/>
				{questions.map((el) => (
					<Field
						label={el.text}
						field={'textField'}
						style={{ color: '#9B9B9B', fontWeight: 'bold', width: isMobile ? '100%' : '556px' }}
						value={el.value}
						onChange={onQuestionsChange(el.id)}
						maxLength={100}
						fieldClass={'single'}
					/>
				))}
				<Field
					label={'Are you intending to study full time or part time?'}
					options={timesOptions}
					field={'radioInput'}
					style={{ color: '#9B9B9B', fontWeight: 'bold', width: '150px' }}
					value={full_time}
					onChange={onChange('full_time')}
					isInline
					fieldClass={'single'}
				/>{' '}
				<Field
					label={'Will you be training for ordination?'}
					options={booleanOptions}
					field={'radioInput'}
					style={{ color: '#9B9B9B', fontWeight: 'bold', width: '150px' }}
					value={church_of_england}
					onChange={onChange('church_of_england')}
					isInline
					fieldClass={'single'}
					isRequired
					error={errors.church_of_england}
				/>
				<Field
					label={'If you would like to defer your entry to CTF, please state month and year of your prefered date of entry'}
					field={'textField'}
					style={{ color: '#9B9B9B', fontWeight: 'bold', width: isMobile ? '100%' : '556px' }}
					value={launching_educational}
					onChange={onChange('launching_educational')}
					maxLength={100}
					fieldClass={'single'}
				/>{' '}
			</div>
		</div>
	)
}
